.bgvideo {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

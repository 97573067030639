.content2 {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.pageTitle {
  font-size: 8vw;
  margin: 0 0 0 0;
  margin-bottom: -2.1%;
  text-shadow: -1px 1px 0 rgba(129, 129, 129, 0.2),
    1px 1px 0 rgba(129, 129, 129, 0.2), 1px -1px 0 rgba(129, 129, 129, 0.2),
    -1px -1px 0 rgba(129, 129, 129, 0.2);
  align-self: flex-end;
  color: white;
}

.slideTitle {
  /* font-size: 10vw;
    margin: 0 0 0 0;
    margin-bottom: -4.6vh;
    text-shadow: -1px 1px 0 rgba(129, 129, 129, 0.2), 1px 1px 0 rgba(129, 129, 129, 0.2), 1px -1px 0 rgba(129, 129, 129, 0.2),
      -1px -1px 0 rgba(129, 129, 129, 0.2);
    cursor: default; */
  grid-column-start: 2;
  grid-column-end: 9;
  align-self: flex-end;
}

.programming {
  /* display: flex; */
  grid-column-start: 2;
  grid-column-end: 4;
  /* background-color: red; */
  align-self: center;
}

.modeling {
  /* display: flex; */
  grid-column-start: 4;
  /* background-color: blue; */
  align-self: center;
}

.visualization {
  /* display: flex; */
  align-self: center;
  /* background-color: pink; */
}

.backtesting {
  /* display: flex; */

  grid-column-start: 6;
  grid-column-end: 8;
  align-self: center;
  /* background-color: orange; */
}

.content2 h4 {
  margin: 0 0;
  font-size: 2vw;
  font-weight: 8000;
  /* margin-top: 0.2em; */
}

.content2 p {
  font-size: x-small;
  margin-right: 2vw;
  font-size: 1vw;
  color: gray;
}

.content2 img {
  z-index: 20;
  width: 90%;
}

.aiming {
  grid-column-start: 2;
  grid-column-end: 4;
}

.circle1,
.circle2,
.circle3 {
  border-radius: 50%;
  border: solid 1px;
  border-color: rgba(129, 129, 129, 0.2);
}

.circle1 {
  margin-top: 5%;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.circle2 {
  transform: translate(25%, 25%);
  width: 66%;
  height: 0;
  padding-bottom: 66%;
}

.circle3 {
  transform: translate(25%, 25%);
  width: 66%;
  height: 0;
  padding-bottom: 66%;
  text-align: center;
}

.plus {
  padding-top: 22.5%;
  padding-top: 30%;
  /* margin: auto; */
  font-size: 3vw;
  stroke-width: 1px;
  color: #486c90;
}

.circle3 span {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  top: 23%;
  color: #242424;
  font-size: 3vw;
  font-weight: bolder;
}

.circle3 span div {
  padding-left: 4%;
}

.circle3 span h2 {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  top: -68%;
  left: 125%;
  font-size: 3vw;

  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(129, 129, 129, 0.2);
}

.toDevelop {
  grid-column-start: 5;
  grid-column-end: 9;
  margin-right: 1vw;
  /* margin-bottom: 2vw; */

  /* align-self: center; */
}

.toDevelop h1 {
  font-size: 3vw;
  font-weight: bolder;
  margin-top: auto;
  margin-bottom: auto;
}

.heart {
  padding-top: 22.5%;
  padding-top: 38%;
  /* margin: auto; */
  font-size: 2vw;
  /* stroke-width: 1px; */
  color: #486c90;
}

h2 svg {
  /* font-size: 3vw; */
  /* stroke-width: 1px; */
  color: #486c90;
}

.values1 {
  grid-column-start: 5;
  grid-column-end: 7;
  /* align-self: center; */
}

.values2 {
  grid-column-start: 7;
  grid-column-end: 9;
  /* align-self: center; */
}

.values1,
.values2 {
  margin-right: 2vw;
  margin-bottom: 2vw;
  padding-top: auto;
}

.values1 h2,
.values2 h2 {
  font-size: 2vw;
  font-weight: bolder;
  margin-bottom: auto;
}

.leftPanel {
  grid-column-start: 1;
  grid-column-end: 6;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  position: relative;
}

/* .leftPanel div {
  align-self: center;
  align-items: center;
  justify-content: center;
  position: relative;
} */


.leftPanel blockquote {
  /* background-color: blue; */
  /* flex: 1.5; */
  padding-top: 10%;
  margin: 0 0 3% 0;
  align-self: center;
  justify-content: center;
  color: rgb(190, 190, 190);
}

.leftPanel blockquote h4 {
  font-size: 2.5vw;
  color: rgb(190, 190, 190);
}

.leftPanel .quote {
  color: transparent;
  stroke-width: 0.2px;
  stroke: rgb(190, 190, 190);
  font-size: 14vw;
  position: absolute;
  margin: -15% 0 0 -15%;
}

.leftPanel .logo{
  height: 25vw;
  width: 25vw;
  
}

.leftPanel .photo{
  bottom: 0%;
  left: 4%;
  width: 20vw;
  z-index: 100;
  position: absolute;
  /* height: 25vw;
  width: 25vw; */
  /* border-bottom: 0; */
}
blockquote {
  z-index: 100000;

}
.rightPanel {
  z-index: 1000;
  height: 100%;
  background-color: #484848;
  grid-column-start: 6;
  grid-column-end: 9;
  margin: 0 0 0 0;
  color: white;
  padding-left: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.rightPanel h1 {
  font-size: 3vw;
  margin-bottom: auto;
}

.rightPanel p {
  font-size: 1.5vw;
  /* margin: auto; */
}

.rightPanel span {
  /* margin: auto; */
  justify-self: flex-start;
}

.endingText {
  align-self: center;
  grid-column-start: 2;
  grid-column-end: 4;
}

.sidebar {
  position: fixed;
  background-color: #484848;
  display: flex;
  float: left;
  width: 5vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 2;
}

.sidebarLogo {
  cursor: pointer;
  margin: 0px;
  margin-top: 4vh;
  color: #242424;
  display: flex;
  justify-content: center;
}

.logoImg {
  width: 90%;
}

.paginationContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.pagination {
  cursor: pointer;
  align-self: center;
  height: 3vw;
  background-color: grey;
  border: solid 1.5px;
  border-radius: 2px;
  border-color: grey;
  display: inline-block;
  margin-bottom: 1.1vw;
  cursor: pointer;
  transition: all 0.3s;
}

.pagination:hover:not(.active) {
  /* animation: hightlight 0.4s ease-in-out forwards; */
  background-color: #cacaca;
  border-color: #cacaca;
}

.active {
  animation: hightlight 0.4s ease-in-out forwards;
  background-color: white;
  border-color: white;
}

.home, .services, .projects, .about, .contact{
  align-self: center;
}
/* @keyframes hightlight {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
} */

.social {
  color: grey;
  margin-bottom: 0;
  transition: all 0.3s;

}

.social .active  {
  color: white;
}

.social:hover {
  color: #cacaca;
}

.socialContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5vh;
}

.retangle-logo-mobile {
  height: 40vmin;
  width: 40vmin;
  pointer-events: none;
  position: absolute;
}

.dash-logo-mobile {
  height: 40vmin;
  width: 40vmin;
  pointer-events: none;
  position: absolute;

}

.letters-logo-mobile {
  height: 40vmin;
  width: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .retangle-logo-mobile {
    animation: Animation-logo-spin infinite 20s linear;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .dash-logo-mobile {
    animation: Animation-logo-fade infinite 2.5s linear;
  }
}


@keyframes Animation-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Animation-logo-fade {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.startanimator {
  
  cursor: pointer;
  justify-self: right;
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
}

.startcontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 6vh;
  cursor: pointer;
}

.letter {
  font-size: 5vw;
  writing-mode: vertical-lr;
  rotate: 180deg;
  color: transparent;
  -webkit-text-stroke-width: 0.1vw;
  -webkit-text-stroke-color: #486c90;
  align-self: center;
  transition: all 0.4s;
}

.arrow {
  cursor: pointer;
  color:transparent ;
  stroke-width: 0.025vw;
  stroke: #486c90;
  font-size: 5vw;
  align-self: center;
  transition: all 0.4s ease-in-out;
}

.startcontainer:hover .arrow{
  transform: translateY(2.5vh);
  stroke: white;
}

.startcontainer:hover .letter{
  color: #486c90;
}


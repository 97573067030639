@import url('https://fonts.googleapis.com/css?family=Nunito');

.fullContent {
  background-color: rgb(49, 49, 49);
  background: url('../img/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  margin: 0%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  
}

.fullContent p {
  font-family: 'Nunito', sans-serif;
  font-size: 4vh;
  font-style: normal;
  color: white;
}


.animationMobile {
    height: 40vmin;
    width: 100%;
    overflow: hidden;
}

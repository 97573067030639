.retangle-logo {
  pointer-events: none;
  position: absolute;
  width: 90%;
  transition: transform 4s linear;
}

.dash-logo {
  pointer-events: none;
  position: absolute;
  width: 90%;
  transition: transform 5s linear;
}

/* .letters-logo > g {
  fill: white;
}


.letters-logo > path {
  stroke: white;
  stroke-width: 10px;
 } */

.letters-logo {
  pointer-events: none;
  width: 90%;
}


.sidebarLogo > svg {
  max-height: 4vw;
}

.sidebarLogo:hover > svg {
  filter: invert(97%) sepia(100%) saturate(1%) hue-rotate(189deg) brightness(103%) contrast(101%);
}


.sidebarLogo:hover > .retangle-logo {
  transform: rotate(360deg);
  /* animation: Animation-logo-spin infinite 10s linear; */
}

.sidebarLogo:hover > .dash-logo {
  animation: Animation-logo-fade infinite 1.125s linear;
}

/* @keyframes Animation-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

@keyframes Animation-logo-fade {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

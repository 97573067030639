.fullLine {
  height: 100vh;
  position: fixed;
}

.small,
.large,
.mid {
  animation: 3s linear slideline;
  border-left:  solid 1px;
  /* border-color: #242424; */
  border-color: rgba(129, 129, 129, 0.2);
  align-items: center;
}

@keyframes slideline {
  0% {
      transform: translateY(-100%);
  }
  100% {
      transform: translateY(0);
  }
}

#line2 {
  grid-column: 1;
  grid-row: 1 / 3;
}

#line3 {
  grid-column: 2;
  grid-row: 1 / 3;
}

#line4 {
  grid-column: 3;
  grid-row: 1 / 3;
}

#line5 {
  grid-column: 4;
  grid-row: 1 / 3;
}

#line6 {
  grid-column: 5;
  grid-row: 1 / 3;
}

#line7 {
  grid-column: 6;
  grid-row: 1 / 3;
}

#line8 {
  grid-column: 7;
  grid-row: 1 / 3;
}

#line9 {
  grid-column: 8;
  grid-row: 1 / 3;
}

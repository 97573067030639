.top {
  margin-top: 6vw;
  flex: 0.4;
  flex-shrink: 1;
  /* background-color: blue; */
}

.botton {
  flex: 1;
  /* flex-grow: 1; */
  background-color: white;
  /* align-items: center; */
}

.ending {
  flex: 0.3;
}
.defaultWidth {
  margin: 0;
  margin-left: 5vw;
  padding: 0;
  width: calc(100vw - 5vw);
  max-width: calc(100% - 5vw);
}

.defaultGrid {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  /* position: fixed; */
}

.description {
  cursor: default;
  grid-column-start: 3;
  grid-column-end: 9;
  align-self: end;
  position: relative;
  margin-bottom: 6vh;
  float: left;
  color: white;

}

.tagline{
  width: 10vw;
  border: solid 1px;
  border-radius: 10px;
  border-color:#486c90;
  margin: 0;
  display: inline-block;
  margin-right: 1vw;
}


.tagname{
  display: inline-block;
  font-size: 1.5vw;
  margin: 0;
  height: 0.1vw;
}

.job{
  font-size: 10vw;
  margin: 0;
}

.jobline1,
.jobline2 {
  height: 10vw;
  /* margin: 0px; */
  display: block;
}

.jobcolored{
  margin: 0;
  color: #486c90;
}
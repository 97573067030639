.contactMe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16vw;
  height: 4vw;

  font-size: 1.1vw;
  font-weight: 750;

  margin-top: 10%;

  cursor: pointer;
  text-align: center;
  text-rendering: geometricprecision;
  vertical-align: middle;
  box-sizing: border-box;
  border: solid 1px;
  color: #486c90;

  background-color: white;
  border-color: white;
  transition: all 0.4s ease-in-out;
}

.contactMe > span {
  cursor: pointer;
  font-size: 1.1vw;
  font-weight: 750;
  color: #486c90;
  transition: all 0.4s ease-in-out;
}

.contactMe:hover,
.contactMe:active {
  border-color: #486c90;
  color: #486c90;
  background-color: initial;
}

.rArrow {
  font-size: 2vw;
  align-self: center;

  padding-left: 1.5vw;
  -webkit-text-stroke: 1vw;
  transition: all 0.4s ease-in-out;
}

.contactMe:hover > .rArrow {
  transform: translateX(1vw);
}

@import url("https://fonts.googleapis.com/css?family=Sen");
html {
  font-family: "Sen", sans-serif;
}

/*html {*/
/*  scroll-snap-type: y mandatory;*/
/*}*/

body {
  margin: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */


#root {
  border: solid 0;
  margin: 0;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

#container {
  position: absolute;
  overflow: auto;
  height: 100vh;
}

/*section {*/
/*  scroll-snap-align: start;*/
/*}*/

body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}


.navBar {
  padding: 0;
  width: calc(100vw - 5vw);
  max-width: calc(100% - 5vw);
  height: 6vw;
  position: fixed;
  top: 0;
  z-index: 1;
  margin: 0;
  margin-left: 5vw;
}

.navMenu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  flex-direction: column;
  margin: 0px;
  padding: 0 0 0 0;
  color: #242424;
  align-items: center;
  height: 6vw;
  list-style-type: none;
  margin-left: 5vw; 
}

.navItem > span {
  cursor: pointer;
  font-size: 1.1vw;
  font-weight: 750;
  color: #486c90;
  transition: all 0.4s ease-in-out;
}
/* .services, .projects, .about, .contact{
  color: #486c90;
} */

.navItem > span:hover {
  animation: hightlight 0.4s ease-in-out forwards;
  color: white;
}

@keyframes hightlight {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.servicesItem {
  grid-column-start: 2;
}

.projectsItem {
  grid-column-start: 4;
}

.abouItem {
  grid-column-start: 5;
}

.contactItem {
  grid-column-start: 6;
}

.hireContainer {
  grid-column-start: 8;
}

.hireItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12vw;
  height: 3vw;

  font-size: 1.1vw;
  font-weight: 750;

  cursor: pointer;
  text-align: center;
  text-rendering: geometricprecision;
  vertical-align: middle;
  box-sizing: border-box;
  border: solid 1px;
  color: #242424;

  background-color: #486c90;
  border-color: #486c90;
  transition: all 0.4s ease-in-out;
}

.hireItem:hover,
.hireItem:active {
  color: #486c90;
  background-color: initial;
}

.rArrow {
  font-size: 2vw;
  align-self: center;

  padding-left: 1.5vw;
  -webkit-text-stroke: 1vw;
  transition: all 0.4s ease-in-out;
}

.hireItem:hover > .rArrow {
  transform: translateX(1vw);
}

.active-title span {
  /* animation: hightlight 0.4s ease-in-out forwards; */
  color: white;
}
